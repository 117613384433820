<template>
  <div v-if="pdfUrl" style="text-align: right; padding-right: 5px"><a target="_blank" :href="pdfUrl" download>Download Contract of Employment</a></div>

  <div style="height: 70vh">
    <div v-if="loadingPdf" class="mt-5"><v-progress-circular indeterminate color="primary"></v-progress-circular></div>
    <vue-pdf-app v-show="!loadingPdf" v-if="pdfUrl" theme="light" :pdf="pdfUrl" :config="pdfConfig" @pages-rendered="loadingPdf = false"></vue-pdf-app>
  </div>

  <v-row no-gutters class="align-end" v-if="!loadingPdf">
    <v-col cols="12">
      <div class="checkbox-wrapper">
        <v-checkbox v-if="!signed" v-model="global.isSigned" label="I confirm that I have read and understood the terms of the agreement." :disabled="signed" @change="signContract"></v-checkbox>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import { globalStore } from "@/stores/globalstore";
export default {
  name: "main-View",
  data() {
    return {
      contract: null,
      global: globalStore(),
      pdfUrl: null,
      guid: null,
      message: "Document Signing Portal",
      errored: false,
      status: null,
      buttonDisabled: false,
      loadingPdf: true,
      signed: false,
      pdfConfig: {
        sidebar: false,
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        secondaryToolbar: false,
      },
    };
  },
  watch: {
    "global.isSaving": async function (isSaving) {
      // watch it
      if (isSaving) {
        if (this.global.isSigned) {
          this.askSubmitDocument();
        }
      }
    },
  },
  methods: {
    async init() {
      this.global.isContract = true;
      this.guid = decodeURI(this.$route.params.guid);
      this.pdfUrl = `${this.global.webApi}Contract/GetContract/${this.guid}`;
      var response = await this.global.webApiGet(`Contract/${this.guid}`);
      if (!this.global.lastApiError) {
        this.contract = response.data; 
        console.log("***CONTRACT***",this.contract);
      }
      if (this.contract.dateSigned) {
        this.confirmed = true;
        this.isSigned = true;
        this.signed = true;
        this.global.isSigned = true;
        this.global.isSubmitted = true;
        this.pdfConfig.toolbar.toolbarViewerRight.download = true;
        this.pdfConfig.toolbar.toolbarViewerRight.print = true;
      }
    },
    getPdfUrl() {
      var url = `${this.global.webApi}Contract/GetContract${this.guid}`;
      console.log("***PDF URL***",url);
      return url;
    },
    signContract() {
      this.contract.dateSigned = new Date();
      this.global.isValidated = true;
    },
    askSubmitDocument() {
      this.global.showConfirm("Submit Document", "This will submit your signed document to Payments Pro. Are you sure you wish to continue?", this.saveDocument, "Submit", this.cancelSubmitDocument, "Cancel");
    },
    cancelSubmitDocument() {
      this.global.isSaving = false;
      this.global.showConfirmDialog = false;
    },
    async saveDocument() {
      this.global.showConfirmDialog = false;
      this.isBusy = true;
      await this.global.webApiPut(`Contract/${this.guid}`, this.contract);
      this.isBusy = false;
      if (!this.global.lastApiError) {
        // do something here
        this.showSaved = true;
        if (this.contract.dateSigned) {
          this.global.isSubmitted = true;
          this.signed=true;
        }
      } else {
        this.errored = true;
      }
      this.global.isSaving = false;
    },
  },
  computed: {},
  async mounted() {
    await this.init();
  },
  components: { VuePdfApp },
};
</script>

<style>
.logo-container {
  width: 300px;
  margin: 0 auto;
  margin-top: 25px;
}

.st0 {
  fill: #363842;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_1_);
}

.logo-subtitle {
  margin-top: 10px;
  font-size: 1.5em;
  font-weight: bold;
}

.checkbox-wrapper {
  width: 300px;
  margin: 0 auto;
}

.checkbox-wrapper .v-input__details {
  display: none;
}
</style>
