<template>
  <div id="page-header"></div>
  <div id="logo-block">
    <v-row no-gutters>
      <v-col cols="auto" class="me-auto hidden-sm-and-down">
        <img src="/logo.png" />
      </v-col>
      <v-col cols="auto">
        <v-btn class="save_button" :loading="global.isSaving" v-if="!global.isContract && !global.isSubmitted && (!global.isValidated || !global.isSigned)" :disabled="global.isSaving" @click="global.isSaving = true">Save Progress</v-btn>
        <v-btn class="save_button" :loading="global.isSaving" v-if="!global.isSubmitted && (global.isValidated && global.isSigned)" :disabled="global.isSaving" @click="global.isSaving = true">Submit Document</v-btn>
      </v-col>
    </v-row>
  </div>
  <div id="content-container">
    <v-container id="page-container">
      <v-row no-gutters>
        <v-col cols="1" md="2"></v-col>
        <v-col>
          <router-view />
        </v-col>
        <v-col cols="1" md="2"></v-col>
      </v-row>
    </v-container>
  </div>

  <v-dialog
      v-model="global.showConfirmDialog"
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{global.confirmTitle}}</span>
        </v-card-title>
        <v-card-text>
          {{global.confirmText}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-blue-darken-4"
            variant="text"
            @click="global.confirmCancel"
          >
            {{global.confirmCancelText}}
          </v-btn>
          <v-btn
          color="light-blue-darken-4"
            variant="text"
            @click="global.confirmOk"
          >
          {{global.confirmOkText}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "App",
  data() {
    return {
      global: globalStore(),
    };
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    },
  },
  components: {},
  async mounted() {},

  beforeUnmount() {},
};
</script>

<style>
#content-container {
  overflow-y: scroll;
  max-height: calc(100vh - 124px) !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

#page-container {
  padding: 0px !important;
}

#page-header {
  width: 100%;
  background-color: #053b77;
  border-color: #ffffff;
  height: 40px;
}

#logo-block {
  height: 83px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-bottom: 1px solid #053b77;
}

#logo-block .save_button {
  margin-right: 15px;
  margin-top: 8px;
}

html {
  overflow-y: auto !important;
  font-size: 0.9rem !important;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  overflow: hidden;
}
</style>
