<template>
  <v-progress-circular v-if="!initialDetails" :size="50" color="primary" indeterminate></v-progress-circular>
  <v-form v-if="!global.isSubmitted" :disabled="global.isSaving">
    <v-expansion-panels v-if="initialDetails" v-model="accordian">
      <v-expansion-panel title="Personal Details" value="personal_details" id="personal_details-panel">
        <v-expansion-panel-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field v-model="initialDetails.title" label="Title" v-on:input="validateForm" maxlength="50"></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field v-model="initialDetails.firstName" label="First Name" v-on:input="validateForm" maxlength="255">
                  <template #details v-if="validationErrors.firstName">
                    <div class="error-message">{{ validationErrors.firstName }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field v-model="initialDetails.lastName" label="Last Name" v-on:input="validateForm" maxlength="255">
                  <template #details v-if="validationErrors.lastName">
                    <div class="error-message">{{ validationErrors.lastName }}</div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="initialDetails.addressLine1" label="Address Line 1" v-on:input="validateForm">
                  <template #details v-if="validationErrors.addressLine1">
                    <div class="error-message">{{ validationErrors.addressLine1 }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="initialDetails.addressLine2" label="Address Line 2" v-on:input="validateForm">
                  <template #details v-if="validationErrors.addressLine2">
                    <div class="error-message">{{ validationErrors.addressLine2 }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="9" cols="12">
                <v-text-field v-model="initialDetails.addressLine3" label="City" v-on:input="validateForm">
                  <template #details v-if="validationErrors.addressLine3">
                    <div class="error-message">{{ validationErrors.addressLine3 }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field v-model="initialDetails.postCode" label="Postcode" v-on:input="validateForm">
                  <template #details v-if="validationErrors.postCode">
                    <div class="error-message">{{ validationErrors.postCode }}</div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <v-text-field v-model="initialDetails.dateOfBirth" label="Date Of Birth" type="date" v-on:input="validateForm">
                  <template #details v-if="validationErrors.dateOfBirth">
                    <div class="error-message">{{ validationErrors.dateOfBirth }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="initialDetails.niNumber" label="National Insurance No" v-on:input="validateForm" maxlength="9">
                  <template #details v-if="validationErrors.niNumber">
                    <div class="error-message">{{ validationErrors.niNumber }}</div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <v-text-field v-model="initialDetails.mobileNumber" label="Mobile Number" v-on:input="validateForm" maxlength="13">
                  <template #details v-if="validationErrors.mobileNumber">
                    <div class="error-message">{{ validationErrors.mobileNumber }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="initialDetails.homePhoneNumber" label="Home Phone Number" v-on:input="validateForm" maxlength="13">
                  <template #details v-if="validationErrors.homePhoneNumber">
                    <div class="error-message">{{ validationErrors.homePhoneNumber }}</div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <v-text-field v-model="initialDetails.jobTitle" label="Job Title/Profession"></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-select v-model="initialDetails.nationality" label="Nationality" :items="global.nationalityList">
                  <template #details v-if="validationErrors.nationality">
                    <div class="error-message">{{ validationErrors.nationality }}</div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="initialDetails.emailAddress" label="Email Address" v-on:input="validateForm">
                  <template #details v-if="validationErrors.emailAddress">
                    <div class="error-message">{{ validationErrors.emailAddress }}</div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" cols="12">
                <v-text-field v-model="initialDetails.agencyName" label="Agency Name"></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field v-model="initialDetails.agencyContactNumber" label="Agency Contact Number" maxlength="255"></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field v-model="initialDetails.agencyContactName" label="Agency Contact Name" maxlength="255"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto" class="me-auto"></v-col>
              <v-col cols="auto">
                <v-btn @click="setAccordian('payment_details')">Payment Details &gt;</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel title="Payment Details" value="payment_details" id="payment_details-panel">
        <v-expansion-panel-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select v-model="initialDetails.bankAccountType" label="Account Type" :items="accountTypes"></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="initialDetails.bankName" label="Name of Bank/Building Society" v-on:input="validateForm">
                  <template #details v-if="validationErrors.bankName">
                    <div class="error-message">{{ validationErrors.bankName }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="initialDetails.bankLocation" label="Branch Location" v-on:input="validateForm">
                  <template #details v-if="validationErrors.bankLocation">
                    <div class="error-message">{{ validationErrors.bankLocation }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="initialDetails.bankSortCode" label="Sort Code" v-on:input="validateForm" maxlength="8">
                  <template #details v-if="validationErrors.bankSortCode">
                    <div class="error-message">{{ validationErrors.bankSortCode }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="initialDetails.bankAccountNumber" label="Account Number" v-on:input="validateForm" maxlength="8">
                  <template #details v-if="validationErrors.bankAccountNumber">
                    <div class="error-message">{{ validationErrors.bankAccountNumber }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="initialDetails.bankAccountName" label="Name of Account Holder" v-on:input="validateForm" maxlength="255">
                  <template #details v-if="validationErrors.bankAccountName">
                    <div class="error-message">{{ validationErrors.bankAccountName }}</div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="initialDetails.bankRollNumber" label="Roll Number (if applicable)" maxlength="255"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto" class="me-auto">
                <v-btn @click="setAccordian('personal_details')">&lt; Personal Details</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="setAccordian('working_with')">Working with Payments Pro &gt;</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel title="Working with Payments Pro" value="working_with" id="working_with-panel">
        <v-expansion-panel-text>
          <v-container>
            <v-row>
              <v-col class="working-with" cols="12">
                <p>Our umbrella service is here to provide you with a hassle-free way of getting paid for your contracting services. We work alongside your agency to ensure you are paid efficiently and on time. We run daily, weekly and monthly payroll based on your agencies request.</p>
                <ul>
                  <li>
                    <p><strong>Umbrella PAYE</strong></p>
                    <p>When you sign up with our PAYE service you will become our employee, we fulfil the role as employer for contractors. This provides you with the same statuary rights that that full- time PAYE employees enjoy such as SSP, maternity leave and holiday pay.</p>
                  </li>
                  <li>
                    <p><strong>Umbrella CIS</strong></p>
                    <p>If you are working within the Construction Industry Scheme (CIS) your eligibility will need to be confirmed by your agency and you will need a valid UTR.</p>
                  </li>
                  <li>
                    <p><strong>Carrying out work</strong></p>
                    <p>Your shifts and assignments will still be given to you by your agency. It will be our duty to pay you for the work you have done once we have received the details and funds from your agency.</p>
                  </li>
                  <li>
                    <p><strong>Contract of Employment</strong></p>
                    <p>WOnce you have completed our sign-up form you will need to read and accept your Contract of Employment with us. This will be provided within your sign-up pack.</p>
                  </li>
                  <li>
                    <p><strong>PAYE Deductions</strong></p>
                    <p>We will make all of the correct and compliant HMRC deductions on your behalf. This includes tax, NI, Apprenticeship Levy & NIERS. We also deduct our weekly margin (only when you work), student loan (dependent on circumstance) and your auto-enrolment pension contribution (dependent on circumstance).</p>
                  </li>
                  <li>
                    <p><strong>CIS Deductions</strong></p>
                    <p>You will pay a flat-rate tax deduction of 20% on valid UTR's. UTR's that do no validate will be charged at 30%. We also deduct our weekly margin (only when you work). If you have Gross status, you may be eligible for 0% CIS tax rate.</p>
                  </li>
                  <li>
                    <p><strong>Hourly rate</strong></p>
                    <p>This is set and agreed by your agency. Any negotiations on your hourly rate must be carried out with your agency. We cannot change or adjust your hourly rate without explicit instructions from your agency.</p>
                  </li>
                  <li>
                    <p><strong>Expenses</strong></p>
                    <p>All employees are considered under SDC (Supervision, Direction &mp; Control). You will not be able to claim expenses through Payments Pro directly. Any expenses will need to be agreed and authorised by your agency. Once authorised your agency will send these to us along with the funds to pay you.</p>
                  </li>
                  <li>
                    <p><strong>Tax code</strong></p>
                    <p>When working with our umbrella service you will have a single tax code. This means you can work with multiple agencies and companies at the same time but still effectively have only one employer, Payments Pro.</p>
                  </li>
                  <li>
                    <p><strong>IR35 &amp; Compliance</strong></p>
                    <p>When you become an employee of our umbrella company you can rest assured that all of your HMRC contributions are deducted and paid compliantly. Your status as defined under IR35 is legally met.</p>
                  </li>
                  <li>
                    <p><strong>Conduct of Employment Agencies and Employment Businesses Regulations 2003</strong></p>
                    <p>All agency workers are considered within by default. If you or your agency/end-client has requested that you opt-out of the conduct regulations, you or the agency must do so in writing requesting so. The opt-out must be signed by both yourself and Payments Pro. Any requests to opt back in must follow the same process.</p>
                    <p>
                      <strong>Please Note:</strong>
                      Employees working with vulnerable persons are not eligible to opt out of the Conduct of Employment Agencies and Employment Businesses Regulations 2003
                    </p>
                  </li>
                  <li>
                    <p><strong>Holiday Pay</strong></p>
                    <p>
                      You have the right to 5.6 weeks (28 days) per annum. You can request to have this paid out in every payslip or retained for redemption when you wish to take leave. This is calculated and shown in each payslip you receive. The holiday entitlement of 5.6 weeks is equivalent to 12.07% of hours worked over a year. The 12.07per cent figure is 5.6 weeks’ holiday, divided by 46.4 weeks (being 52 weeks – 5.6 weeks). The 5.6 weeks are excluded from the calculation, as the worker would not be at
                      work during those 5.6 weeks in order to accrue annual leave. So, if someone works 10 hours, they are entitled to 72.6 minutes paid holiday (12.07/100 x 10 =1.21 hours = 72.63 minutes).
                    </p>
                  </li>
                  <p>
                    Our dedicated helpdesk has in-depth knowledge of the recruiting and payroll industry. They should always be your first point of contact for any questions you may have, or for any help you may require in regards to our service or your account. Please email
                    <a href="mailto:helpme@paymentspro.co.uk">helpme@paymentspro.co.uk</a>
                    for any support requests.
                  </p>
                </ul>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox @change="signWorkingWith" :disabled="global.isSaving" v-model="workingWithSigned" label="Tick the box to confirm you have read and understood all of the information provided above"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto" class="me-auto">
                <v-btn @click="setAccordian('payment_details')">&lt; Payment Details</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="setAccordian('right_to_work')">Your Right to Work &gt;</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel title="Your Right to Work" value="right_to_work" id="right_to_work-panel">
        <v-expansion-panel-text>
          <v-container>
            <v-row>
              <v-col class="right-to-work" cols="12">
                <p>
                  <strong>Confirmation of your identity and right to work in the UK</strong>
                </p>
                <p>As an employer, we have a responsibility to prevent illegal working in the UK by ensuring that our employees have the right to work. Under the terms of the Immigration, Asylum and Nationality Act 2006, we are required to make basic checks on everyone we intend to employ and you will be required to supply the relevant right to work documents. In respect to verification of your identity you will be required to supply the following documents;</p>

                <p><strong>Proof of your identity</strong></p>
                <ul>
                  <li>Proof of your address - A recent bank statement (from the last three months)</li>
                  <li>A copy of your passport</li>
                  <li>A clear copy of Visa or work permit (only if required)</li>
                </ul>

                <p>
                  We are unable to process any payments unless these documents have been received and we can confirm all ID verification checks have been passed. For proof of Right To Work will need to visit https://www.gov.uk/prove-right-to-work where you will be able to generate a code that will allow us to check your status. Without this code you will need to prove your identity in person or on a video call with the relevant documents. More information on proving your Right To Work can be found by
                  visiting
                  <a target="_blank" href="https://www.paymentspro.co.uk/right-to-work/">https://www.paymentspro.co.uk/right-to-work/</a>
                </p>

                <p><strong>Please Upload:</strong></p>
                <ul>
                  <li>Proof of ID</li>
                  <li>Proof of address</li>
                  <li>DBS (if relevant)</li>
                  <li>Right to work code</li>
                </ul>

                <v-row>
                  <v-col md="10" cols="12">
                    <v-file-input v-model="rtwFiles" label="Upload your files here" multiple></v-file-input>
                  </v-col>
                  <v-col md="2" cols="12" v-if="rtwFiles.length > 0">
                    <v-btn :loading="isUploading" v-if="rtwFiles.length > 0" class="upload-button" @click="uploadRtw()">Upload</v-btn>
                  </v-col>
                </v-row>

                <p>
                  If you cannot upload the required documents, please email them to
                  <a href="mailto:helpme@paymentspro.co.uk">helpme@paymentspro.co.uk</a>
                  stating your full name.
                </p>

                <p><strong>Terms and Conditions</strong></p>
                <p>My pay and holiday pay (‘my wages’) will be calculated in accordance with the requirements of the National Minimum Wage and the Working Time Directive as applied from time to time. Payments Pro to which I am subscribing will charge the agreed fee, which is one fee per week (only when you work). We will only charge you once a week no matter how many timesheets you submit that week (for that week). I understand that Payments Pro Ltd to which I am subscribing will:</p>

                <ol>
                  <li>Pay my wages.</li>
                  <li>Deduct the 'fee' as stated above.</li>
                </ol>

                <p>
                  I acknowledge that I am responsible for ensuring prompt delivery of signed timesheets to the agency in accordance with their payment schedule and if required a copy to be faxed or emailed to Payments Pro. I acknowledge that Payments Pro Ltd can only ensure that my wages will be credited into my bank or building society account on time if the umbrella company has received confirmation of my hours, rate and appropriate funds from the agency to cover my wages. I acknowledge that Payments Pro
                  Ltd pays all wages both daily and weekly, which will take approximately one working day to clear into my account. Bank holidays will affect payment dates. I declare that I am not aware of any circumstance(s) that could give rise to either an Employers’ Liability or Public Liability insurance claim in respect of any incident(s) which occurred during the period from the date of commencing the first assignment for Payments Pro Ltd and the date of completing the registration form.
                </p>
                <p><strong>Declaration</strong></p>
                <p>
                  I have read and understood the acknowledgments above. I understand that I will receive payments through Payments Pro as an agent for the umbrella company that will employ me. By signing this application form, I am consenting to Payments Pro holding and processing personal data and sensitive personal data). Further, I agree to such data being released to third parties (such as Trade Union and insurance providers) where necessary. I agree that all the information supplied in this form is
                  correct and true to the best of my knowledge. I will send a certified copy of my passport and signed contract of employment. I confirm that the above information is true and correct to the best of my knowledge and by submitting this form I agree that my application to join Payments Pro will be dealt with subject to status. I authorise Payments Pro to contact my agency or end-user client and authorise them to disclose any information relating to me and my assignment details.
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox @change="signRtw" :disabled="global.isSaving" v-model="rtwSigned" label="Tick the box to confirm you have read and understood all of the information provided above"></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto" class="me-auto">
                <v-btn @click="setAccordian('working_with')">&lt; Working with Payments pro</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="setAccordian('declaration')">Declaration &gt;</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel title="Declaration" value="declaration" id="declaration-panel">
        <v-expansion-panel-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  :disabled="!global.isValidated || !rtwSigned || !workingWithSigned || global.isSaving"
                  v-model="isSigned"
                  label="I declare that the information I have provided is correct and I understand that it is my responsibility to inform Payments Pro Ltd of any changes to my
personal details as soon as possible. I acknowledge that by signing below I agree Payments Pro Ltd may store my details in accordance with the Data
Protection act 1998."
                  @change="signDocument()"
                >
                  <template #details v-if="!global.isValidated"><div class="error-message">Please complete all of the required fields before signing the declaration</div></template>
                </v-checkbox>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="signedName" label="Signed" readonly></v-text-field>
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="dateSignedFormatted" label="Date" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto" class="me-auto">
                <v-btn @click="setAccordian('right_to_work')">&lt; Your Right to Work</v-btn>
              </v-col>
              <v-col cols="auto"></v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>

  <v-card v-if="global.isSubmitted" variant="elevated" color="light-blue-darken-4" width="auto" title="Document Submitted" text="Thank you for submitting the document to Payments Pro."></v-card>

  <v-snackbar v-if="!global.isSigned" v-model="showSaved" :timeout="timeout" color="light-blue-darken-4">
    <p>Your progress has been saved.</p>
    <p>You may continue completing this document at any time.</p>
  </v-snackbar>
  <v-snackbar v-if="global.isSigned" v-model="showSaved" :timeout="timeout" color="light-blue-darken-4">The document has been submitted.</v-snackbar>
  <v-snackbar v-if="global.isSigned" v-model="errored" :timeout="timeout" color="red-lighten-1">An error occurred whilst submitting the document.</v-snackbar>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "Initial-Details-View",
  watch: {
    "global.isSaving": async function (isSaving) {
      // watch it
      if (isSaving) {
        if (this.global.isSigned) {
          this.askSubmitDocument();
        } else {
          this.saveDocument();
        }
      }
    },
  },
  data() {
    return {
      isSaving: false,
      global: globalStore(),
      guid: null,
      initialDetails: null,
      errored: false,
      accordian: ["personal_details"],
      accountTypes: [
        { title: "Bank Account", value: "BANK_ACCOUNT" },
        { title: "Building Society Account", value: "BUILDING_SOCIETY" },
      ],
      isSigned: false,
      workingWithSigned: false,
      rtwSigned: false,
      rtwFiles: [],
      isUploading: false,
      signedName: "",
      dateSignedFormatted: "",
      isValidated: false,
      showSaved: false,
      timeout: 4000,
      validationErrors: {
        firstName: null,
        lastName: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        postCode: null,
        niNumber: null,
        mobileNumber: null,
        homePhoneNumber: null,
        nationality: null,
        dateOfBirth: null,
        emailAddress: null,
        dateSigned: null,
        bankName: null,
        bankLocation: null,
        bankSortCode: null,
        bankAccountNumber: null,
        bankAccountName: null,
      },
    };
  },
  methods: {
    async init() {
      this.global.isContract = false;
      this.accordian = ["personal_details"];
      this.guid = decodeURI(this.$route.params.guid);
      var response = await this.global.webApiGet(`InitialDetails/${this.guid}`);
      if (!this.global.lastApiError) {
        console.log("***GET***", response);
        this.initialDetails = this.fixDob(response.data);
        if (!this.initialDetails.bankAccountType) {
          this.initialDetails.bankAccountType = "BANK_ACCOUNT";
        }
        if (this.initialDetails.dateSigned) {
          this.global.isSubmitted = true;
        }
        if (this.initialDetails.workingWithDateSigned) {
          this.global.workingWithSigned = true;
        }
        if (this.initialDetails.rtwDateSigned) {
          this.global.rtwSigned = true;
        }
        this.validateForm();
      } else {
        this.errored = true;
      }
    },

    askSubmitDocument() {
      this.global.showConfirm("Submit Document", "This will submit your signed document to Payments Pro. Are you sure you wish to continue?", this.saveDocument, "Submit", this.cancelSubmitDocument, "Cancel");
    },
    cancelSubmitDocument() {
      this.global.isSaving = false;
      this.global.showConfirmDialog = false;
    },
    async saveDocument() {
      this.global.showConfirmDialog = false;
      this.isBusy = true;
      await this.global.webApiPut(`InitialDetails/${this.guid}`, this.initialDetails);
      this.isBusy = false;
      if (!this.global.lastApiError) {
        // do something here
        this.showSaved = true;
        if (this.initialDetails.dateSigned) {
          this.global.isSubmitted = true;
        }
      } else {
        this.errored = true;
      }
      this.global.isSaving = false;
    },
    signWorkingWith() {
      if (this.workingWithSigned) {
        this.initialDetails.workingWithDateSigned = new Date();
      } else {
        this.initialDetails.workingWithDateSigned = null;
      }
    },
    signRtw() {
      if (this.rtwSigned) {
        this.initialDetails.rtwDateSigned = new Date();
      } else {
        this.initialDetails.rtwDateSigned = null;
      }
    },
    signDocument() {
      console.log(this.initialDetails);
      if (this.isSigned) {
        this.signedName = `${this.initialDetails.firstName.toUpperCase()} ${this.initialDetails.lastName.toUpperCase()}`;
        this.initialDetails.dateSigned = new Date();
        this.dateSignedFormatted = this.initialDetails.dateSigned.toLocaleDateString("en-GB");
        this.global.isSigned = true;
      } else {
        this.signedName = "";
        this.initialDetails.dateSigned = null;
        this.dateSignedFormatted = "";
        this.global.isSigned = null;
      }
      this.validateForm();
    },
    fixDob(details) {
      if (details.dateOfBirth) {
        details.dateOfBirth = details.dateOfBirth.split("T")[0];
      }
      return details;
    },
    validateForm() {
      let isValidated = true;
      if (this.initialDetails) {
        Object.assign(this.validationErrors, {
          firstName: null,
          lastName: null,
          addressLine1: null,
          addressLine2: null,
          addressLine3: null,
          postCode: null,
          niNumber: null,
          mobileNumber: null,
          homePhoneNumber: null,
          nationality: null,
          dateOfBirth: null,
          emailAddress: null,
          dateSigned: null,
          bankName: null,
          bankLocation: null,
          bankSortCode: null,
          bankAccountNumber: null,
          bankAccountName: null,
        });

        if (!this.initialDetails.firstName) {
          this.validationErrors.firstName = "Please enter your first name";
          isValidated = false;
        }
        if (!this.initialDetails.lastName) {
          this.validationErrors.lastName = "Please enter your last name";
          isValidated = false;
        }

        if (!this.initialDetails.addressLine1) {
          this.validationErrors.addressLine1 = "Please enter the first line of your address";
          isValidated = false;
        }

        if (!this.initialDetails.addressLine2) {
          this.validationErrors.addressLine2 = "Please enter the second line of your address";
          isValidated = false;
        }

        if (!this.initialDetails.addressLine3) {
          this.validationErrors.addressLine3 = "Please enter your city";
          isValidated = false;
        }

        if (!this.initialDetails.postCode) {
          this.validationErrors.postCode = "Please enter your post code";
          isValidated = false;
        } else if (!this.global.validateData(this.initialDetails.postCode, this.global.regex.postCode)) {
          this.validationErrors.postCode = "The entered post code is invalid";
          isValidated = false;
        }

        if (!this.initialDetails.niNumber) {
          this.validationErrors.niNumber = "Please enter your NI number";
          isValidated = false;
        } else if (!this.global.validateData(this.initialDetails.niNumber, this.global.regex.niNumber)) {
          this.validationErrors.niNumber = "The entered NI number is invalid";
          isValidated = false;
        }

        if (!this.initialDetails.mobileNumber && !this.initialDetails.homePhoneNumber) {
          this.validationErrors.mobileNumber = "Please enter at least one contact number";
          this.validationErrors.homePhoneNumber = "Please enter at least one contact number";
          isValidated = false;
        } else {
          console.log("***VALIDATE***", this.initialDetails.mobileNumber, this.global.validateData(this.initialDetails.mobileNumber, this.global.regex.mobileNumber));

          if (this.initialDetails.mobileNumber && !this.global.validateData(this.initialDetails.mobileNumber, this.global.regex.mobileNumber)) {
            this.validationErrors.mobileNumber = "The entered mobile number is invalid";
          }

          if (this.initialDetails.homePhoneNumber && !this.global.validateData(this.initialDetails.homePhoneNumber, this.global.regex.phoneNumber)) {
            this.validationErrors.homePhoneNumber = "The entered home phone number is invalid";
          }
        }

        // if (!this.initialDetails.mobileNumber && !this.initialDetails.homePhoneNumber) {
        //   this.validationErrors.mobileNumber = "Please enter at least one contact number";
        //   this.validationErrors.homePhoneNumber = "Please enter at least one contact number";
        //   isValidated = false;
        // } else if (!this.global.validateData(this.initialDetails.mobileNumber, this.global.regex.phoneNumber)) {
        //   this.validationErrors.mobileNumber = "The entered mobile number is invalid";
        // } else if (!this.global.validateData(this.initialDetails.homePhoneNumber, this.global.regex.phoneNumber)) {
        //   this.validationErrors.homePhoneNumber = "The entered home phone number is invalid";
        // }

        if (!this.initialDetails.nationality) {
          this.validationErrors.nationality = "Please specify your nationality";
          isValidated = false;
        }

        if (!this.initialDetails.dateOfBirth) {
          this.validationErrors.dateOfBirth = "Please specify your date of birth";
          isValidated = false;
        }

        if (!this.initialDetails.emailAddress) {
          this.validationErrors.emailAddress = "Please enter your email address";
          isValidated = false;
        } else if (!this.global.validateData(this.initialDetails.emailAddress, this.global.regex.emailAddress)) {
          this.validationErrors.emailAddress = "The entered email address is invalid";
          isValidated = false;
        }

        if (!this.initialDetails.bankName) {
          this.validationErrors.bankName = "Please enter the name of your bank or building society";
          isValidated = false;
        }

        // if (!this.initialDetails.bankLocation) {
        //   this.validationErrors.bankLocation = "Please enter your the location of your bank or building society branch";
        //   isValidated = false;
        // }

        if (!this.initialDetails.bankSortCode) {
          this.validationErrors.bankSortCode = "Please enter your bank account sort code";
          isValidated = false;
        } else if (!this.global.validateData(this.initialDetails.bankSortCode, this.global.regex.bankSortCode)) {
          this.validationErrors.bankSortCode = "The entered sort code is invalid";
          isValidated = false;
        }

        if (!this.initialDetails.bankAccountNumber) {
          this.validationErrors.bankAccountNumber = "Please enter your bank account number";
          isValidated = false;
        } else if (!this.global.validateData(this.initialDetails.bankAccountNumber, this.global.regex.bankAccountNumber)) {
          this.validationErrors.bankAccountNumber = "The entered account number is invalid";
          isValidated = false;
        }

        if (!this.initialDetails.bankAccountName) {
          this.validationErrors.bankAccountName = "Please enter the name under which your bank account is held";
          isValidated = false;
        }
      }

      this.global.isValidated = isValidated;
    },
    async uploadRtw() {
      var formData = new FormData();
      this.rtwFiles.forEach((f) => {
        formData.append("file", f);
      });

      this.isUploading = true;
      await this.global.webApiPost(`InitialDetails/UploadRtw/${this.guid}`, formData, false, "multipart/form-data");
      if (!this.global.lastApiError) {
        this.rtwFiles = [];
      }
      this.isUploading = false;
    },
    setAccordian(value) {
      this.accordian = [value];
      let id = value + "-panel";
      let element = document.getElementById(id);
      setTimeout(() => {
        element.scrollIntoView();
      }, 500);
    },
  },
  computed: {},
  async mounted() {
    await this.init();
  },
};
</script>

<style scoped>
.error-message {
  color: red;
}

.working-with p,
.right-to-work p {
  margin-bottom: 10px;
}

.working-with ol,
.right-to-work ol {
  margin-bottom: 10px;
}

.working-with ul,
.right-to-work ul {
  margin-bottom: 10px;
}
</style>
