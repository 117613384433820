<template>
  <div v-if="!global.isSubmitted">
    <div v-if="showScreen == 0">
      <v-card class="mb-5" variant="elevated" color="light-blue-darken-4" width="auto" title="Provide your P45 or Starter Checklist" text="Providing a P45 or completing a Starter Checklist will ensure that we can place you on the correct tax code when you commence employment."></v-card>

      <v-row>
        <v-col cols="12" md="auto" class="me-auto">
          <v-btn @click="showScreen = 1">Click here to Upload your P45</v-btn>
        </v-col>
        <v-col cols="12" md="auto">
          <v-btn @click="showScreen = 2">Click here to complete your Starter Checklist</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="showScreen == 1">
      <p>Please upload your P45 below.</p>
      <v-row>
        <v-col md="10" cols="12">
          <v-file-input v-model="p45" label="Upload your P45 here" multiple></v-file-input>
        </v-col>
        <v-col md="2" cols="12" v-if="p45.length > 0">
          <v-btn :loading="isUploading" v-if="p45.length > 0" class="upload-button" @click="uploadP45()">Upload</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="showScreen == 2">
      <v-progress-circular v-if="!starterChecklist" :size="50" color="primary" indeterminate></v-progress-circular>
      <v-form :disabled="global.isSaving">
        <v-expansion-panels v-if="starterChecklist" v-model="accordian">
          <v-expansion-panel title="Personal Details" value="personal_details">
            <v-expansion-panel-text>
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pr-2">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.lastName" label="Last Name" v-on:input="validateForm" maxlength="255">
                          <template #details v-if="validationErrors.lastName">
                            <div class="error-message">{{ validationErrors.lastName }}</div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <v-text-field v-model="starterChecklist.firstName" label="First Name(s)" v-on:input="validateForm" maxlength="255" hide-details="auto">
                          <template #details v-if="validationErrors.firstName">
                            <div class="error-message">{{ validationErrors.firstName }}</div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.firstName2" v-on:input="validateForm" maxlength="255"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-radio-group v-model="starterChecklist.gender" inline label="Are you male or female?" @change="validateForm">
                          <v-radio label="Male" value="Male"></v-radio>
                          <v-radio label="Female" value="Female"></v-radio>
                          <template #details v-if="validationErrors.gender">
                            <div class="error-message">{{ validationErrors.gender }}</div>
                          </template>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.dateOfBirth" label="Date Of Birth" type="date" v-on:input="validateForm">
                          <template #details v-if="validationErrors.dateOfBirth">
                            <div class="error-message">{{ validationErrors.dateOfBirth }}</div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" class="pl-2">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.addressLine1" label="Home Address" v-on:input="validateForm" hide-details="auto">
                          <template #details v-if="validationErrors.addressLine1">
                            <div class="error-message">{{ validationErrors.addressLine1 }}</div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.addressLine2" hide-details="auto"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.addressLine3" hide-details="auto"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.postCode" label="Postcode" v-on:input="validateForm" hide-details="auto">
                          <template #details v-if="validationErrors.postCode">
                            <div class="error-message">{{ validationErrors.postCode }}</div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select v-model="starterChecklist.country" label="Country" :items="global.countryList">
                          <template #details v-if="validationErrors.country">
                            <div class="error-message">{{ validationErrors.country }}</div>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.niNumber" label="National Insurance number" v-on:input="validateForm" maxlength="9">
                          <template #details v-if="validationErrors.niNumber">
                            <div class="error-message">{{ validationErrors.niNumber }}</div>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field v-model="starterChecklist.employmentStartDate" label="Employment start date" type="date" v-on:input="validateForm">
                          <template #details v-if="validationErrors.employmentStartDate">
                            <div class="error-message">{{ validationErrors.employmentStartDate }}</div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" class="me-auto"></v-col>
                  <v-col cols="auto">
                    <v-btn @click="accordian = ['employee_statement']">Employee Statement &gt;</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel title="Employee Statement" value="employee_statement">
            <v-expansion-panel-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.employeeStatement" label="You need to select only one of the following statements:" @change="validateForm">
                      <v-radio label="This is my first job since 6 April and I've not been receiving taxable Jobseeker's Allowance, Employment and Support Allowance, taxable Incapacity Benefit, State or Occupational Pension." value="A"></v-radio>
                      <v-radio label="This is now my only job but since 6 April I've had another job, or received taxable Jobseeker's Allowance, Employment and Support Allowance or taxable Incapacity Benefit. I do not receive a State or Occupational Pension." value="B"></v-radio>
                      <v-radio label="As well as my new job, I have another job or receive a State or Occupational Pension." value="C"></v-radio>
                      <template #details v-if="validationErrors.employeeStatement">
                        <div class="error-message">{{ validationErrors.employeeStatement }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" class="me-auto">
                    <v-btn @click="accordian = ['personal_details']">&lt; Personal Details</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="accordian = ['student_loan']">Student Loan &gt;</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel title="Student Loan" value="student_loan">
            <v-expansion-panel-text>
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.hasStudentLoan" label="Do you have one of the Student Loan Plans described below which is not fully repaid?" @change="validateForm">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                      <template #details v-if="validationErrors.hasStudentLoan">
                        <div class="error-message">{{ validationErrors.hasStudentLoan }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.slCompletedBefore6April" label="Did you complete or leave your studies before 6th April?" :disabled="!starterChecklist.hasStudentLoan || global.isSaving" @change="validateForm">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                      <template #details v-if="validationErrors.slCompletedBefore6April">
                        <div class="error-message">{{ validationErrors.slCompletedBefore6April }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.slRepayingDirect" label="Are you repaying your Student Loan directly to the Student Loans Company by direct debit?" :disabled="!(starterChecklist.hasStudentLoan && starterChecklist.slCompletedBefore6April) || global.isSaving" @change="validateForm">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                      <template #details v-if="validationErrors.slRepayingDirect">
                        <div class="error-message">{{ validationErrors.slRepayingDirect }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.studentLoanType" inline label="What type of Student Loan do you have?" :disabled="!(starterChecklist.hasStudentLoan && starterChecklist.slCompletedBefore6April && !starterChecklist.slRepayingDirect) || global.isSaving" @change="validateForm">
                      <v-radio label="Plan 1" value="1"></v-radio>
                      <v-radio label="Plan 2" value="2"></v-radio>
                      <v-radio label="Plan 4" value="4"></v-radio>
                      <template #details v-if="validationErrors.studentLoanType">
                        <div class="error-message">{{ validationErrors.studentLoanType }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <h2>Student Loan Plans</h2>
                    <h3>You have Plan 1 if any of the following apply:</h3>
                    <div style="padding-left: 25px; padding-bottom: 5px">
                      <ul>
                        <li>You lived in Northern Ireland when you started your course</li>
                        <li>You lived in England or Wales and started your course before 1 September 2012</li>
                      </ul>
                    </div>
                    <h3>You have Plan 2 if:</h3>
                    <div style="padding-left: 25px; padding-bottom: 5px">
                      <ul>
                        <li>You lived in England or Wales and started your course on or after 1 September 2012</li>
                      </ul>
                    </div>
                    <h3>You have Plan 4 if:</h3>
                    <div style="padding-left: 25px; padding-bottom: 5px">
                      <ul>
                        <li>You lived in Scotland and applied through the Students Award Agency Scotland (SAAS) when you started your course</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" class="me-auto">
                    <v-btn @click="accordian = ['employee_statement']">&lt; Employee Statement</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="accordian = ['postgraduate_loan']">Postgraduate Loan &gt;</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel title="Postgraduate Loan" value="postgraduate_loan">
            <v-expansion-panel-text>
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.hasPostgradLoan" label="Do you have a Postgraduate Loan which is not fully repaid?" @change="validateForm">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                      <template #details v-if="validationErrors.hasPostgradLoan">
                        <div class="error-message">{{ validationErrors.hasPostgradLoan }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.pgCompletedBefore6April" label="Did you complete or leave your Postgraduate studies before 6th April?" :disabled="!starterChecklist.hasPostgradLoan || global.isSaving" @change="validateForm">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                      <template #details v-if="validationErrors.pgCompletedBefore6April">
                        <div class="error-message">{{ validationErrors.pgCompletedBefore6April }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group v-model="starterChecklist.pgRepayingDirect" label="Are you repaying your Postgraduate Loan directly to the Student Loans Company by direct debit?" :disabled="!(starterChecklist.hasPostgradLoan && starterChecklist.pgCompletedBefore6April) || global.isSaving" @change="validateForm">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                      <template #details v-if="validationErrors.pgRepayingDirect">
                        <div class="error-message">{{ validationErrors.pgRepayingDirect }}</div>
                      </template>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="auto" class="me-auto">
                    <v-btn @click="accordian = ['student_loan']">&lt; Student Loan</v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="accordian = ['declaration']">Declaration &gt;</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel title="Declaration" value="declaration">
            <v-expansion-panel-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox :disabled="!global.isValidated || global.isSaving" v-model="isSigned" label="I confirm that the information I've given on this form is correct" @change="signDocument()">
                      <template #details v-if="!global.isValidated"><div class="error-message">Please complete all of the required fields before signing the declaration</div></template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="signedName" label="Signed" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="dateSignedFormatted" label="Date" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" class="me-auto">
                    <v-btn @click="accordian = ['postgraduate_loan']">&lt; Postgraduate Loan</v-btn>
                  </v-col>
                  <v-col cols="auto"></v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </div>
  </div>

  <v-card v-if="global.isSubmitted" variant="elevated" color="light-blue-darken-4" width="auto" title="Document Submitted" text="Thank you for submitting the document to Payments Pro."></v-card>

  <v-snackbar v-if="!global.isSigned" v-model="showSaved" :timeout="timeout" color="light-blue-darken-4">
    <p>Your progress has been saved.</p>
    <p>You may continue completing this document at any time.</p>
  </v-snackbar>
  <v-snackbar v-if="global.isSigned" v-model="showSaved" :timeout="timeout" color="light-blue-darken-4">The document has been submitted.</v-snackbar>
  <v-snackbar v-if="global.isSigned" v-model="errored" :timeout="timeout" color="red-lighten-1">An error occurred whilst submitting the document.</v-snackbar>
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  name: "Initial-Details-View",
  watch: {
    "global.isSaving": async function (isSaving) {
      // watch it
      if (isSaving) {
        if (this.global.isSigned) {
          this.askSubmitDocument();
        } else {
          this.saveDocument();
        }
      }
    },
  },
  data() {
    return {
      showScreen: 0,
      isSaving: false,
      global: globalStore(),
      guid: null,
      starterChecklist: null,
      errored: false,
      accordian: ["personal_details"],
      isSigned: false,
      signedName: "",
      dateSignedFormatted: "",
      isValidated: false,
      showSaved: false,
      timeout: 4000,
      p45: [],
      isUploading: false,
      validationErrors: {
        lastName: null,
        firstName: null,
        addressLine1: null,
        postCode: null,
        niNumber: null,
        gender: null,
        dateOfBirth: null,
        employmentStartDate: null,
        employeeStatement: null,
        hasStudentLoan: null,
        slCompletedBefore6April: null,
        slRepayingDirect: null,
        studentLoanType: null,
        hasPostgradLoan: null,
        pgCompletedBefore6April: null,
        pgRepayingDirect: null,
        dateSigned: null,
        dateP45Uploaded: null,
      },
    };
  },
  methods: {
    async init() {
      this.global.isContract = false;
      this.accordian = ["personal_details"];
      this.guid = decodeURI(this.$route.params.guid);
      var response = await this.global.webApiGet(`StarterChecklist/${this.guid}`);
      if (!this.global.lastApiError) {
        this.starterChecklist = this.fixDates(response.data);
        console.log(this.starterChecklist);
        if (this.starterChecklist.dateSigned || this.starterChecklist.dateP45Uploaded) {
          this.global.isSubmitted = true;
        }
        this.validateForm();
      } else {
        this.errored = true;
      }
    },

    askSubmitDocument() {
      this.global.showConfirm("Submit Document", "This will submit your signed document to Payments Pro. Are you sure you wish to continue?", this.saveDocument, "Submit", this.cancelSubmitDocument, "Cancel");
    },
    cancelSubmitDocument() {
      this.global.isSaving = false;
      this.global.showConfirmDialog = false;
    },
    async saveDocument() {
      this.global.showConfirmDialog = false;
      this.isBusy = true;
      await this.global.webApiPut(`StarterChecklist/${this.guid}`, this.starterChecklist);
      this.isBusy = false;
      if (!this.global.lastApiError) {
        // do something here
        this.showSaved = true;
        if (this.starterChecklist.dateSigned) {
          this.global.isSubmitted = true;
        }
      } else {
        this.errored = true;
      }
      this.global.isSaving = false;
    },
    signDocument() {
      console.log(this.starterChecklist);
      if (this.isSigned) {
        this.signedName = `${this.starterChecklist.firstName.toUpperCase()} ${this.starterChecklist.lastName.toUpperCase()}`;
        this.starterChecklist.dateSigned = new Date();
        this.dateSignedFormatted = this.starterChecklist.dateSigned.toLocaleDateString("en-GB");
        this.global.isSigned = true;
      } else {
        this.signedName = "";
        this.starterChecklist.dateSigned = null;
        this.dateSignedFormatted = "";
        this.global.isSigned = null;
      }
      this.validateForm();
    },
    fixDates(checklist) {
      if (checklist.dateOfBirth) {
        checklist.dateOfBirth = checklist.dateOfBirth.split("T")[0];
      }

      if (checklist.employmentStartDate) {
        checklist.employmentStartDate = checklist.employmentStartDate.split("T")[0];
      }

      return checklist;
    },
    validateForm() {
      let isValidated = true;

      Object.assign(this.validationErrors, {
        lastName: null,
        firstName: null,
        addressLine1: null,
        postCode: null,
        niNumber: null,
        gender: null,
        dateOfBirth: null,
        employmentStartDate: null,
        employeeStatement: null,
        hasStudentLoan: null,
        slCompletedBefore6April: null,
        slRepayingDirect: null,
        studentLoanType: null,
        hasPostgradLoan: null,
        pgCompletedBefore6April: null,
        pgRepayingDirect: null,
        dateSigned: null,
        dateP45Uploaded: null,
      });

      if (!this.starterChecklist.lastName) {
        this.validationErrors.lastName = "Please enter your last name";
        isValidated = false;
      }

      if (!this.starterChecklist.firstName) {
        this.validationErrors.firstName = "Please enter your first name(s)";
        isValidated = false;
      }

      if (!this.starterChecklist.addressLine1) {
        this.validationErrors.addressLine1 = "Please enter your address";
        isValidated = false;
      }

      if (!this.starterChecklist.postCode) {
        this.validationErrors.postCode = "Please enter your post code";
        isValidated = false;
      } else if (!this.global.validateData(this.starterChecklist.postCode, this.global.regex.postCode)) {
        this.validationErrors.postCode = "The entered post code is invalid";
        isValidated = false;
      }

      if (!this.starterChecklist.niNumber) {
        this.validationErrors.niNumber = "Please enter your NI number";
        isValidated = false;
      } else if (!this.global.validateData(this.starterChecklist.niNumber, this.global.regex.niNumber)) {
        this.validationErrors.niNumber = "The entered NI number is invalid";
        isValidated = false;
      }

      if (!this.starterChecklist.gender) {
        this.validationErrors.gender = "Please select your gender";
        isValidated = false;
      }

      if (!this.starterChecklist.dateOfBirth) {
        this.validationErrors.dateOfBirth = "Please enter your date of birth";
        isValidated = false;
      }

      if (!this.starterChecklist.employmentStartDate) {
        this.validationErrors.employmentStartDate = "Please enter your employment start date";
        isValidated = false;
      }

      if (!this.starterChecklist.employeeStatement) {
        this.validationErrors.employeeStatement = "Please choose one of the statements";
        isValidated = false;
      }

      if (this.starterChecklist.hasStudentLoan == null) {
        this.validationErrors.hasStudentLoan = "Please specify if you have a student loan";
        isValidated = false;
      }

      if (this.starterChecklist.hasStudentLoan && this.starterChecklist.slCompletedBefore6April == null) {
        this.validationErrors.slCompletedBefore6April = "Please complete this field";
        isValidated = false;
      }

      if (this.starterChecklist.hasStudentLoan && this.starterChecklist.slCompletedBefore6April && this.starterChecklist.slRepayingDirect == null) {
        this.validationErrors.slRepayingDirect = "Please complete this field";
        isValidated = false;
      }

      if (this.starterChecklist.hasStudentLoan && this.starterChecklist.slCompletedBefore6April && this.starterChecklist.slRepayingDirect == false && !this.starterChecklist.studentLoanType) {
        this.validationErrors.slRepayingDirect = "Please select a student loan type";
        isValidated = false;
      }

      if (this.starterChecklist.hasPostgradLoan == null) {
        this.validationErrors.hasPostgradLoan = "Please specify if you have a Postgraduate loan";
        isValidated = false;
      }

      if (this.starterChecklist.hasPostgradLoan && this.starterChecklist.pgCompletedBefore6April == null) {
        this.validationErrors.pgCompletedBefore6April = "Please complete this field";
        isValidated = false;
      }

      if (this.starterChecklist.hasPostgradLoan && this.starterChecklist.pgCompletedBefore6April && this.starterChecklist.pgRepayingDirect == null) {
        this.validationErrors.pgRepayingDirect = "Please complete this field";
        isValidated = false;
      }

      this.global.isValidated = isValidated;
    },
    async uploadP45() {
      var formData = new FormData();
      this.p45.forEach((f) => {
        formData.append("file", f);
      });

      this.isUploading = true;
      await this.global.webApiPost(`StarterChecklist/UploadP45/${this.guid}`, formData, false, "multipart/form-data");
      if (!this.global.lastApiError) {
        this.p45 = [];
        this.global.isSubmitted = true;
      }
      this.isUploading = false;
    },
  },
  computed: {},
  async mounted() {
    await this.init();
  },
};
</script>

<style>
.error-message {
  color: red;
}

.v-radio-group .v-label {
  opacity: 1 !important;
}
</style>
