<template>
This page is intentionally empty.
</template>

<script>
import { globalStore } from "@/stores/globalstore";
export default {
  data() {
    return {
      global: globalStore()
    };
  },
  methods: {
   
  },
  computed: {},
  async mounted() {
   
  },
  watch: {
    global: {
     
    },

  },
  components: {  },
};
</script>

<style scoped>

</style>
