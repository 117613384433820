import { defineStore } from "pinia"
import axios from "axios";

const isDev = (process.env.NODE_ENV || "").trim() !== "production";

export const globalStore = defineStore("global", {
    state: () => {
        return {
            testValue: 0,
            webApi: isDev ? "https://localhost:7078/" : "/api/",
            lastApiError: null,
            trueFalseYesNo: [
                {
                    title: "Yes",
                    value: true,
                },
                {
                    title: "No",
                    value: false,
                },
            ],
            confirmTitle: null,
            confirmText: null,
            confirmOk: null,
            confirmOkText: "OK",
            confirmCancel: null,
            confirmCancelText: "Cancel",
            showConfirmDialog: false,
            confirmBusy: false,
            isDirty: false,
            isSaving: false,
            isValidated: false,
            isSigned: false,
            isSubmitted: false,
            isContract: false,
            regex: {
                niNumber: /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]{0,1}$/,
                postCode: /^(GIR 0AA)|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))\s?[0-9][A-Z]{2})$/,
                emailAddress: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                bankAccountNumber: /^(?!(?:0{8}|01234567|12345678))(\d){8}$/,
                bankSortCode: /[0-9]{2}-?[0-9]{2}-?[0-9]{2}$/,
                phoneNumber: /^(((\+44\s?\d{4}|0\d{4})\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|0\d{3})\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|0\d{2})\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/, //eslint-disable-line
                mobileNumber: /^((0|44|\+44|\+44\s*\(0\)|\+44\s*0)\s*)?7(\s*[0-9]){9}/ //eslint-disable-line
            },
            countryList: [
                "Afghanistan",
                "Albania",
                "Algeria",
                "American Samoa",
                "Andorra",
                "Angola",
                "Anguilla",
                "Antarctica",
                "Antigua and Barbuda",
                "Argentina",
                "Armenia",
                "Aruba",
                "Australia",
                "Austria",
                "Azerbaijan",
                "Bahamas",
                "Bahrain",
                "Bangladesh",
                "Barbados",
                "Belarus",
                "Belgium",
                "Belize",
                "Benin",
                "Bermuda",
                "Bhutan",
                "Bolivia",
                "Bonaire, Sint Eustatius and Saba",
                "Bosnia and Herzegovina",
                "Botswana",
                "Bouvet Island",
                "Brazil",
                "British Indian Ocean Territory",
                "Brunei Darussalam",
                "Bulgaria",
                "Burkina Faso",
                "Burundi",
                "Cabo Verde",
                "Cambodia",
                "Cameroon",
                "Canada",
                "Cayman Islands",
                "Central African Republic",
                "Chad",
                "Chile",
                "China",
                "Christmas Island",
                "Cocos (Keeling) Islands",
                "Colombia",
                "Comoros (the)",
                "Congo (the Democratic Republic of the)",
                "Congo",
                "Cook Islands",
                "Costa Rica",
                "Croatia",
                "Cuba",
                "Curaçao",
                "Cyprus",
                "Czechia",
                "Côte d'Ivoire",
                "Denmark",
                "Djibouti",
                "Dominica",
                "Dominican Republic",
                "Ecuador",
                "Egypt",
                "El Salvador",
                "Equatorial Guinea",
                "Eritrea",
                "Estonia",
                "Eswatini",
                "Ethiopia",
                "Falkland Islands",
                "Faroe Islands",
                "Fiji",
                "Finland",
                "France",
                "French Guiana",
                "French Polynesia",
                "French Southern Territories",
                "Gabon",
                "Gambia",
                "Georgia",
                "Germany",
                "Ghana",
                "Gibraltar",
                "Greece",
                "Greenland",
                "Grenada",
                "Guadeloupe",
                "Guam",
                "Guatemala",
                "Guernsey",
                "Guinea",
                "Guinea-Bissau",
                "Guyana",
                "Haiti",
                "Heard Island and McDonald Islands",
                "Holy See",
                "Honduras",
                "Hong Kong",
                "Hungary",
                "Iceland",
                "India",
                "Indonesia",
                "Iran",
                "Iraq",
                "Ireland",
                "Isle of Man",
                "Israel",
                "Italy",
                "Jamaica",
                "Japan",
                "Jersey",
                "Jordan",
                "Kazakhstan",
                "Kenya",
                "Kiribati",
                "Korea (the Democratic People's Republic of)",
                "Korea (the Republic of)",
                "Kuwait",
                "Kyrgyzstan",
                "Lao People's Democratic Republic",
                "Latvia",
                "Lebanon",
                "Lesotho",
                "Liberia",
                "Libya",
                "Liechtenstein",
                "Lithuania",
                "Luxembourg",
                "Macao",
                "Madagascar",
                "Malawi",
                "Malaysia",
                "Maldives",
                "Mali",
                "Malta",
                "Marshall Islands",
                "Martinique",
                "Mauritania",
                "Mauritius",
                "Mayotte",
                "Mexico",
                "Micronesia",
                "Moldova",
                "Monaco",
                "Mongolia",
                "Montenegro",
                "Montserrat",
                "Morocco",
                "Mozambique",
                "Myanmar",
                "Namibia",
                "Nauru",
                "Nepal",
                "Netherlands",
                "New Caledonia",
                "New Zealand",
                "Nicaragua",
                "Niger (the)",
                "Nigeria",
                "Niue",
                "Norfolk Island",
                "Northern Mariana Islands",
                "Norway",
                "Oman",
                "Pakistan",
                "Palau",
                "Palestine",
                "Panama",
                "Papua New Guinea",
                "Paraguay",
                "Peru",
                "Philippines",
                "Pitcairn",
                "Poland",
                "Portugal",
                "Puerto Rico",
                "Qatar",
                "Republic of North Macedonia",
                "Romania",
                "Russian Federation",
                "Rwanda",
                "Réunion",
                "Saint Barthélemy",
                "Saint Helena, Ascension and Tristan da Cunha",
                "Saint Kitts and Nevis",
                "Saint Lucia",
                "Saint Martin (French part)",
                "Saint Pierre and Miquelon",
                "Saint Vincent and the Grenadines",
                "Samoa",
                "San Marino",
                "Sao Tome and Principe",
                "Saudi Arabia",
                "Senegal",
                "Serbia",
                "Seychelles",
                "Sierra Leone",
                "Singapore",
                "Sint Maarten (Dutch part)",
                "Slovakia",
                "Slovenia",
                "Solomon Islands",
                "Somalia",
                "South Africa",
                "South Georgia and the South Sandwich Islands",
                "South Sudan",
                "Spain",
                "Sri Lanka",
                "Sudan (the)",
                "Suriname",
                "Svalbard and Jan Mayen",
                "Sweden",
                "Switzerland",
                "Syrian Arab Republic",
                "Taiwan",
                "Tajikistan",
                "Tanzania, United Republic of",
                "Thailand",
                "Timor-Leste",
                "Togo",
                "Tokelau",
                "Tonga",
                "Trinidad and Tobago",
                "Tunisia",
                "Turkey",
                "Turkmenistan",
                "Turks and Caicos Islands",
                "Tuvalu",
                "Uganda",
                "Ukraine",
                "United Arab Emirates",
                "United Kingdom",
                "United States Minor Outlying Islands",
                "United States of America",
                "Uruguay",
                "Uzbekistan",
                "Vanuatu",
                "Venezuela",
                "Viet Nam",
                "Virgin Islands (British)",
                "Virgin Islands (U.S.)",
                "Wallis and Futuna",
                "Western Sahara",
                "Yemen",
                "Zambia",
                "Zimbabwe",
                "Åland Islands"
            ],
            nationalityList: [
                "Afghan",
                "Albanian",
                "Algerian",
                "American",
                "Andorran",
                "Angolan",
                "Antiguans",
                "Argentinean",
                "Armenian",
                "Australian",
                "Austrian",
                "Azerbaijani",
                "Bahamian",
                "Bahraini",
                "Bangladeshi",
                "Barbadian",
                "Barbudans",
                "Batswana",
                "Belarusian",
                "Belgian",
                "Belizean",
                "Beninese",
                "Bhutanese",
                "Bolivian",
                "Bosnian",
                "Brazilian",
                "British",
                "Bruneian",
                "Bulgarian",
                "Burkinabe",
                "Burmese",
                "Burundian",
                "Cambodian",
                "Cameroonian",
                "Canadian",
                "Cape Verdean",
                "Central African",
                "Chadian",
                "Chilean",
                "Chinese",
                "Colombian",
                "Comoran",
                "Congolese",
                "Costa Rican",
                "Croatian",
                "Cuban",
                "Cypriot",
                "Czech",
                "Danish",
                "Djibouti",
                "Dominican",
                "Dutch",
                "East Timorese",
                "Ecuadorean",
                "Egyptian",
                "Emirian",
                "Equatorial Guinean",
                "Eritrean",
                "Estonian",
                "Ethiopian",
                "Fijian",
                "Filipino",
                "Finnish",
                "French",
                "Gabonese",
                "Gambian",
                "Georgian",
                "German",
                "Ghanaian",
                "Greek",
                "Grenadian",
                "Guatemalan",
                "Guinea-Bissauan",
                "Guinean",
                "Guyanese",
                "Haitian",
                "Herzegovinian",
                "Honduran",
                "Hungarian",
                "Icelander",
                "Indian",
                "Indonesian",
                "Iranian",
                "Iraqi",
                "Irish",
                "Israeli",
                "Italian",
                "Ivorian",
                "Jamaican",
                "Japanese",
                "Jordanian",
                "Kazakhstani",
                "Kenyan",
                "Kittian And Nevisian",
                "Kuwaiti",
                "Kyrgyz",
                "Laotian",
                "Latvian",
                "Lebanese",
                "Liberian",
                "Libyan",
                "Liechtensteiner",
                "Lithuanian",
                "Luxembourger",
                "Macedonian",
                "Malagasy",
                "Malawian",
                "Malaysian",
                "Maldivan",
                "Malian",
                "Maltese",
                "Marshallese",
                "Mauritanian",
                "Mauritian",
                "Mexican",
                "Micronesian",
                "Moldovan",
                "Monacan",
                "Mongolian",
                "Moroccan",
                "Mosotho",
                "Motswana",
                "Mozambican",
                "Namibian",
                "Nauruan",
                "Nepalese",
                "New Zealander",
                "Ni-Vanuatu",
                "Nicaraguan",
                "Nigerien",
                "North Korean",
                "Northern Irish",
                "Norwegian",
                "Omani",
                "Pakistani",
                "Palauan",
                "Panamanian",
                "Papua New Guinean",
                "Paraguayan",
                "Peruvian",
                "Polish",
                "Portuguese",
                "Qatari",
                "Romanian",
                "Russian",
                "Rwandan",
                "Saint Lucian",
                "Salvadoran",
                "Samoan",
                "San Marinese",
                "Sao Tomean",
                "Saudi",
                "Scottish",
                "Senegalese",
                "Serbian",
                "Seychellois",
                "Sierra Leonean",
                "Singaporean",
                "Slovakian",
                "Slovenian",
                "Solomon Islander",
                "Somali",
                "South African",
                "South Korean",
                "Spanish",
                "Sri Lankan",
                "Sudanese",
                "Surinamer",
                "Swazi",
                "Swedish",
                "Swiss",
                "Syrian",
                "Taiwanese",
                "Tajik",
                "Tanzanian",
                "Thai",
                "Togolese",
                "Tongan",
                "Trinidadian Or Tobagonian",
                "Tunisian",
                "Turkish",
                "Tuvaluan",
                "Ugandan",
                "Ukrainian",
                "Uruguayan",
                "Uzbekistani",
                "Venezuelan",
                "Vietnamese",
                "Welsh",
                "Yemenite",
                "Zambian",
                "Zimbabwean"
            ]
        }
    },
    actions: {
        async webApiGet(endpoint) {
            this.lastApiError = null;
            const response = await axios
                .get(
                    `${this.webApi}${endpoint}`
                )
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;

                    if (err.response) {
                        console.log("status received");
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });

            return response;
        },
        async webApiPost(endpoint, data, asForm, contentType) {
            this.lastApiError = null;

            let formOptions;

            if (asForm) {

                data = this.getFormData(data);
                formOptions = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }

            } else if (contentType) {
                formOptions = {
                    headers: {
                        "Content-Type": contentType,
                    }
                }

            }

            const response = await axios
                .post(`${this.webApi}${endpoint}`, data, formOptions)
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;

                    if (err.response) {
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });
            return response;
        },
        async webApiPut(endpoint, data, asForm, contentType) {
            this.lastApiError = null;
            let formOptions;

            if (asForm) {

                data = this.getFormData(data);
                formOptions = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }

            } else if (contentType) {
                formOptions = {
                    headers: {
                        "Content-Type": contentType,
                    }
                }

            }

            const response = await axios
                .put(`${this.webApi}${endpoint}`, data, formOptions)
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;
                    if (err.response) {
                        console.log("status received");
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });
            return response;
        },
        async webApiDelete(endpoint) {
            this.lastApiError = null;
            const response = await axios
                .delete(
                    `${this.webApi}${endpoint}`
                )
                .catch((err) => {
                    console.log(err);
                    this.lastApiError = err;
                    if (err.response) {
                        console.log("status received");
                        /*
                         * The request was made and the server responded with a
                         * status code that falls out of the range of 2xx
                         */
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                        if (err.response.status == 401) this.logOut("Your session has expired");
                    } else if (err.request) {
                        console.log("no response received");
                        /*
                         * The request was made but no response was received, `error.request`
                         * is an instance of XMLHttpRequest in the browser and an instance
                         * of http.ClientRequest in Node.js
                         */
                        console.log(err.request);
                        this.logOut("Unable to connect to PDL web service");
                    } else {
                        // Something happened in setting up the request and triggered an Error
                        console.log('Error', err.message);
                    }
                });

            return response;
        },
        async webApiDownload(endpoint, fileName) {
            this.lastApiError = null;
            await axios({
                url: `${this.webApi}${endpoint}`, //your url
                method: "GET",
                responseType: "blob", // important
            }).then((response) => {
                if (!fileName) {
                    let filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
                    let extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
                    let downloadFileName = `${filename}.${extension}`;
                    if (downloadFileName) {
                        fileName = downloadFileName.replace(/"/g, "");
                    }
                }

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute("download", fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
        },
        getFormData(data, formData, parentKey) {
            var self = this;
            if (data === null || data === undefined) return null;
            formData = formData || new FormData();
            if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key =>
                    self.getFormData(data[key], formData, (!parentKey ? key : (data[key] instanceof File ? parentKey : `${parentKey}[${key}]`)))
                );
            } else {
                formData.append(parentKey, data);
            }
            return formData;
        },
        cloneObject(object) {
            return JSON.parse(JSON.stringify(object));
        },
        updateObject(obj/*, …*/) {
            for (var i = 1; i < arguments.length; i++) {
                for (var prop in arguments[i]) {
                    var val = arguments[i][prop];
                    if (typeof val == "object") // this also applies to arrays or null!
                        this.updateObject(obj[prop], val);
                    else
                        obj[prop] = val;
                }
            }
            return obj;
        },
        logOut(logoutError) {
            if (logoutError) {
                this.loginError = logoutError;
            }
            this.session = null;
        },
        showConfirm(title, text, ok, okText, cancel, cancelText) {
            this.confirmTitle = title;
            this.confirmText = text;
            this.confirmOk = ok;
            this.confirmOkText = okText;
            this.confirmCancel = cancel;
            this.confirmCancelText = cancelText;
            this.showConfirmDialog = true;
        },
        validateData(data, regex) {
            if(regex && regex.test) {
                return regex.test(data);
            }
            return false;
        }
    },
    persist: {
        enabled: true
    }
})